$(function() {
  $(document).on('click', '#close-sidebar', function() {
    $('#sidebar').slideToggle();
    $('#event-content').toggleClass("full-width");
    $(this).closest('i').toggleClass("fa-step-backward");
    return $(this).find('i').toggleClass("fa-step-forward");
  });
  $(document).on('click', '#remove-startorder', function() {
    var event_id;
    event_id = $('#event-wrapper').attr('event-id');
    return $.ajax({
      url: "/events/" + event_id + "/remove_startorder",
      type: "get"
    });
  });
  $(document).on('click', '#auto-startorder', function() {
    var event_id, order;
    order = $('.startorder-switch').find('.active').attr('value');
    event_id = $('#event-wrapper').attr('event-id');
    return $.ajax({
      url: "/events/" + event_id + "/auto_startorder?order=" + order,
      type: "get"
    });
  });

  $(document).on('click', '.switch', function() {
    return $(this).find('span').toggleClass('active');
  });
});
